import { Signal, computed } from '@angular/core';
import { FormConfigBuilder } from '@salary/common/component';
import { ProcessStatus } from '@salary/common/dumb';
import { Lohnkontext } from '@salary/common/facade';
import { FieldConfig } from '@salary/common/formly';
import { RZProzess } from '@salary/consolidation/model';
import { ProcessButtonState } from '@salary/multi/process';
import {
  RZProzessArea,
  RZProzessDefinition,
  RZ_PROZESS_AREAS,
} from './rz-prozess-definition';

function applyProcessDefinitionDefaults(
  process: RZProzessDefinition,
): RZProzessDefinition {
  const predefinedDefinition: RZProzessDefinition = {
    iconName: 'mitre',
    description: `Hier können ${process.caption} verwaltet werden.`,
    areaLabel: RZ_PROZESS_AREAS[process.area].label,
    apiDomain: 'apiConsolidationUrl',
    route: process.apiUrl,
    ...process,
  };
  predefinedDefinition.route = predefinedDefinition.route?.toLowerCase();
  if (!predefinedDefinition.endpointConfiguration) {
    predefinedDefinition.endpointConfiguration = {
      url: predefinedDefinition.apiDomain,
      suffix: predefinedDefinition.apiUrl,
    };
  }
  return predefinedDefinition;
}

function createRessourcenPublizierenFieldConfigs(
  ressourceTypes: { label: string; value: number }[],
) {
  return (lohnkontext: Signal<Lohnkontext>) => {
    const b = new FormConfigBuilder();
    const updateModelWithLohnkontext = (
      field: FieldConfig,
      lizenznehmerId: string,
    ) => {
      field.model().lizenznehmerId = lizenznehmerId;
      field.writableRootModel.set(field.model());
    };
    b.select('ressourcenTyp', {
      label: 'Zu publizieren',
      required: true,
      selectOptions: ressourceTypes,
    });
    b.checkbox(undefined, {
      testId: 'restrict_to',
      change: (field, event: { checked: boolean }) => {
        updateModelWithLohnkontext(
          field,
          event.checked ? lohnkontext()?.lizenznehmer?.id : undefined,
        );
      },
      hide: computed(() => !lohnkontext()?.lizenznehmer),
      label: computed(
        () =>
          `Auf Lizenznehmer ${lohnkontext()?.lizenznehmer?.nummer} einschränken.`,
      ),
    });
    return b.build();
  };
}

const RZ_PROZESSE_CORE: RZProzessDefinition[] = [
  {
    caption: 'Lohnsteueranmeldungen (Übermittlungen)',
    apiUrl: 'RzProzessLSTAUebermittlung',
    area: RZProzessArea.Finanzamt,
    detailComponent: () =>
      import(
        './lohnsteueranmeldung-uebermittlung/lohnsteueranmeldung-uebermittlung.component'
      ).then((m) => m.LohnsteueranmeldungUebermittlungComponent),
    modifyColumns: (columns) => {
      const processStatusColumn = columns[columns.length - 1];
      const getButtonState: (model: RZProzess) => ProcessButtonState =
        processStatusColumn.cellRendererOptions['getButtonState'];
      processStatusColumn.cellRendererOptions['getButtonState'] = (
        model: RZProzess,
      ) =>
        getButtonState(
          model['fehler'] && model.processStatus === ProcessStatus.done
            ? { ...model, processStatus: ProcessStatus.doneWithError }
            : model,
        );
      return columns.toSpliced(columns.length - 1, 0, {
        modelPropertyName: 'anzahlUebermittelterDatensaetze',
        columnTitle: 'Anzahl übermittelter Datensätze',
        alignRight: true,
        displayFormat: '{anzahlUebermittelterDatensaetze:d0}',
      });
    },
  },
  {
    caption: 'Lohnsteueranmeldungen (Nullmeldungen)',
    apiUrl: 'RzProzessLSTANullmeldungenErmitteln',
    area: RZProzessArea.Finanzamt,
    detailComponent: () =>
      import(
        './lohnsteueranmeldung-uebermittlung/lohnsteueranmeldungen-list.component'
      ).then((m) => m.LohnsteueranmeldungenListComponent),
    detailComponentData: { asDetail: true },
  },
  {
    caption: 'Lohnsteuerbescheinigungen',
    apiUrl: 'RzProzessLstbUebermittlung',
    area: RZProzessArea.Finanzamt,
  },
  {
    caption: 'Lohnsteuerbescheinigungen Protokolle',
    apiUrl: 'RzProzessLstbBestaetigungenUebermittlung',
    area: RZProzessArea.Finanzamt,
  },
  {
    caption: 'ELO2 Anmeldungen',
    apiUrl: 'RzProzessElo2AnmeldungenUebermittlung',
    area: RZProzessArea.Finanzamt,
  },
  {
    caption: 'ELO2 Bestätigungen',
    apiUrl: 'RzProzessElo2BestaetigungenUebermittlung',
    area: RZProzessArea.Finanzamt,
  },
  {
    caption: 'ELO2 Änderungslisten',
    apiUrl: 'RzProzessElo2AenderungslistenUebermittlung',
    area: RZProzessArea.Finanzamt,
  },
  {
    caption: 'DEÜV',
    area: RZProzessArea.Sozialversicherung,
    apiUrl: 'RzProzessDeuevDateiErstellen',
  },
  {
    caption: 'EEL',
    apiUrl: 'RzProzessEelDateiErstellen',
    detailComponent: () =>
      import(
        './eel-datei-erstellen/eel-datei-erstellen-fehlerliste.component'
      ).then((m) => m.EELDateiErstellenFehlerlisteComponent),
    area: RZProzessArea.Sozialversicherung,
  },
  {
    caption: 'AAG',
    apiUrl: 'RzProzessAagDateiErstellen',
    area: RZProzessArea.Sozialversicherung,
  },
  { caption: 'BV Beiträge', area: RZProzessArea.Sozialversicherung },
  { caption: 'Zahlstellen', area: RZProzessArea.Sozialversicherung },
  {
    caption: 'Beitragsnachweise',
    apiUrl: 'RzProzessBtnwDateiErstellen',
    detailComponent: () =>
      import('./btnw-datei-erstellen/btnw-datei-erstellen.component').then(
        (m) => m.BtnwDateiErstellenComponent,
      ),
    area: RZProzessArea.Sozialversicherung,
  },
  { caption: 'EuBP', area: RZProzessArea.Sozialversicherung },
  {
    caption: 'Versicherungsnummer Abfragen',
    apiUrl: 'RzProzessDSVVDateiErstellen',
    area: RZProzessArea.Sozialversicherung,
  },
  {
    caption: 'Sofortmeldungen',
    apiUrl: 'RzProzessSofortmeldungenDateiErstellen',
    area: RZProzessArea.Sozialversicherung,
  },
  { caption: 'UV-Stamm Abfragen', area: RZProzessArea.Sozialversicherung },
  { caption: 'UV Lohnnachweise', area: RZProzessArea.Sozialversicherung },
  { caption: 'BEA', area: RZProzessArea.Sozialversicherung },
  { caption: 'BEA Protokolle', area: RZProzessArea.Sozialversicherung },
  { caption: 'RV BEA', area: RZProzessArea.Sozialversicherung },
  { caption: 'RV BEA Protokolle', area: RZProzessArea.Sozialversicherung },
  { caption: 'A1 Anträge', area: RZProzessArea.Sozialversicherung },
  { caption: 'A1 Anträge Protokolle', area: RZProzessArea.Sozialversicherung },
  {
    caption: 'eAU',
    apiUrl: 'RzProzessEauDateiErstellen',
    detailComponent: () =>
      import(
        './eau-datei-erstellen/eau-datei-erstellen-fehlerliste.component'
      ).then((m) => m.EAUDateiErstellenFehlerlisteComponent),
    area: RZProzessArea.Sozialversicherung,
  },
  {
    caption: 'eAU Anforderungen wiederholen',
    description: 'Hier können eAU-Anforderungen wiederholt werden.',
    apiUrl: 'RzProzessEauAnforderungenWiederholen',
    area: RZProzessArea.Sozialversicherung,
  },
  {
    caption: 'SV-Rückmeldungen',
    apiUrl: 'RzProzessSvRueckmeldungenEinlesen',
    area: RZProzessArea.Sozialversicherung,
    detailComponent: () =>
      import('./sv-rueckmeldung/sv-rueckmeldungen.component').then(
        (m) => m.SVRueckmeldungenComponent,
      ),
  },
  {
    caption: 'Publikationen aus Administration',
    area: RZProzessArea.Intern,
    apiDomain: 'apiAdministrationUrl',
    apiUrl: 'RzProzessRessourcenPublizieren',
    route: 'RzProzessRessourcenPublizierenAdministration',
    iconName: 'publish',
    parameterFieldConfigs: createRessourcenPublizierenFieldConfigs([
      { label: 'Abrechnungskreise', value: 1 },
      { label: 'Benutzer', value: 2 },
      { label: 'Beschäftigungsbetriebe', value: 3 },
      { label: 'Ansprechpartner', value: 4 },
      { label: 'Bankverbindungen', value: 5 },
      { label: 'Sozialkassen', value: 6 },
      { label: 'Konzerne', value: 7 },
      { label: 'Kostenstellen', value: 8 },
      { label: 'KUG-Abteilungen', value: 9 },
      { label: 'Lizenznehmer', value: 10 },
      { label: 'Finanzämter', value: 11 },
      { label: 'Verbuchungsbetriebe', value: 12 },
      { label: 'RZ-Abrechnungskreise', value: 13 },
      { label: 'Sozialkassen', value: 14 },
      { label: 'Sozialkassenverfahren', value: 15 },
    ]),
  },
  {
    caption: 'Publikationen aus Stammdaten',
    area: RZProzessArea.Intern,
    apiDomain: 'apiMasterdataUrl',
    apiUrl: 'RzProzessRessourcenPublizieren',
    route: 'RzProzessRessourcenPublizierenMasterdata',
    iconName: 'publish',
    parameterFieldConfigs: createRessourcenPublizierenFieldConfigs([
      { label: 'Abteilungen', value: 1 },
      { label: 'Abgrenzungen VB', value: 2 },
      { label: 'Arbeitszeiten', value: 3 },
      { label: 'Arbeitszeitflexibilisierungen', value: 4 },
      { label: 'Berufsgruppen', value: 5 },
      { label: 'Buchungsschemas', value: 6 },
      { label: 'Feiertage', value: 7 },
      { label: 'Kostenstellen', value: 8 },
      { label: 'Krankenkassen', value: 9 },
      { label: 'Lohnarten', value: 10 },
      { label: 'Lohnverbuchungen', value: 11 },
      { label: 'Lohnverbuchungskennzeichen', value: 12 },
      { label: 'Personal', value: 13 },
      { label: 'Sachkontos', value: 14 },
      { label: 'Steuerschlüssel', value: 15 },
      { label: 'Symbole', value: 16 },
      { label: 'Tarifgruppen', value: 17 },
    ]),
  },
  {
    caption: 'Publikationen aus Abrechnung',
    area: RZProzessArea.Intern,
    apiDomain: 'apiCalculationUrl',
    apiUrl: 'RzProzessRessourcenPublizieren',
    route: 'RzProzessRessourcenPublizierenCalculation',
    iconName: 'publish',
    parameterFieldConfigs: createRessourcenPublizierenFieldConfigs([
      { label: 'Beitragsnachweise Krankenkasse', value: 1 },
      { label: 'Durchschnittslöhne', value: 2 },
      { label: 'Jährliche Lohnkonten', value: 3 },
      { label: 'Lohnkonten', value: 4 },
      { label: 'Rückrechnungen', value: 5 },
      { label: 'Vortragswerte Arbeitnehmer', value: 6 },
      { label: 'Vortragswerte AZK', value: 7 },
      { label: 'Vortragswerte EBZ Eintritt AN', value: 8 },
      { label: 'Vortragswerte Urlaubskasse', value: 9 },
      { label: 'Abrechnung', value: 10 },
      { label: 'Beschäftigungsverlauf', value: 11 },
    ]),
  },
  {
    caption: 'Publikationen aus Ermittlung und Übermittlung',
    area: RZProzessArea.Intern,
    apiUrl: 'RzProzessRessourcenPublizieren',
    route: 'RzProzessRessourcenPublizierenConsolidation',
    iconName: 'publish',
    parameterFieldConfigs: createRessourcenPublizierenFieldConfigs([
      { label: 'AAG Meldungen', value: 1 },
      { label: 'Beitragsnachweise ZVK', value: 2 },
      { label: 'Buchungen', value: 3 },
      { label: 'Einzelnachweise ZVK', value: 4 },
      { label: 'KUG Antragsformulare 107', value: 5 },
      { label: 'Lohnsteueranmeldungen', value: 6 },
      { label: 'Lohnsteuerbescheinigungen', value: 7 },
      { label: 'Protokolle zur Abrechnungslisten', value: 8 },
      { label: 'SKUG Abrechnungslisten 308', value: 9 },
      { label: 'SKUG Antragsformulare 307', value: 10 },
      { label: 'Zahlungen', value: 11 },
      { label: 'A1 Antrag Meldung', value: 12 },
      { label: 'eAU Meldung', value: 13 },
    ]),
  },
  {
    caption: 'Publikationen aus Bewegungsdaten',
    area: RZProzessArea.Intern,
    apiDomain: 'apiTransactionsUrl',
    apiUrl: 'RzProzessRessourcenPublizieren',
    route: 'RzProzessRessourcenPublizierenTransactions',
    iconName: 'publish',
    parameterFieldConfigs: createRessourcenPublizierenFieldConfigs([
      { label: 'Abwesenheiten', value: 1 },
      { label: 'Krankheitszeiten', value: 2 },
      { label: 'Lohnartenerfassungen', value: 3 },
      { label: 'Stundenerfassungen', value: 4 },
    ]),
  },
];

export const RZ_PROZESSE: RZProzessDefinition[] = RZ_PROZESSE_CORE.map(
  applyProcessDefinitionDefaults,
);
